import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Everybody Scrolls",
  "tags": "UX Design Research",
  "date": "2014-12-07T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I normally just roll my eyes at any article about “the fold,” but `}<a parentName="p" {...{
        "href": "http://www.hugeinc.com/ideas/perspective/everybody-scrolls"
      }}>{`this one caught my attention`}</a>{`. The UX researchers at `}<a parentName="p" {...{
        "href": "http://www.hugeinc.com"
      }}>{`Huge Inc.`}</a>{` conducted user testing with 48 participants`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` over 3 days and tested four designs:`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.hugeinc.com/ideas/perspective/~/media/images/perspectives%20images/everybody%20scrolls/ux_scrollingresearch.jpg",
        "alt": "This is how the different designs performed. The difference between each is pretty minimal."
      }}></img></p>
    <p>{`As expected, almost everyone scrolled, no matter which design they were served. The trendy arrow pointing down did show a slight increase, but it’s safe to say that it’s not doing much for usability. As time goes on, it’s going to be harder and harder for people to make the argument for shoving everything in a carousel before `}<em parentName="p">{`the fold`}</em>{`. `}<em parentName="p">{`Finally`}</em>{`.`}</p>
    <h4>{`Update`}</h4>
    <p>{`Luke Wroblewski shared a few other interesting data points backing this up.`}</p>
    <blockquote className="twitter-tweet" lang="en"><p>There is no fold. [part 1] <a href="http://t.co/OlcR1tWy7l">pic.twitter.com/OlcR1tWy7l</a></p>&mdash; Luke Wroblewski (@lukew) <a href="https://twitter.com/lukew/status/541986091960528896">December 8, 2014</a></blockquote> <script async src="//platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet" lang="en"><p>There is no fold. [part 2] <a href="http://t.co/zdhLXJoHjb">pic.twitter.com/zdhLXJoHjb</a></p>&mdash; Luke Wroblewski (@lukew) <a href="https://twitter.com/lukew/status/541986175414575104">December 8, 2014</a></blockquote> <script async src="//platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet" lang="en"><p>There is no fold [part 3]. <a href="http://t.co/tlPBLAg1Cn">pic.twitter.com/tlPBLAg1Cn</a></p>&mdash; Luke Wroblewski (@lukew) <a href="https://twitter.com/lukew/status/541986443598393345">December 8, 2014</a></blockquote> <script async src="//platform.twitter.com/widgets.js" charSet="utf-8"></script>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`It’s not a particularly large test group, but we’re not going after scientific results here.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      